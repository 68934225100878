import {deleteISP, updateISP} from "./api.js";
import {getCurrentISPs, hidePlusIcon, renderUpdateTable, selectedRow, showPlusIcon} from "./table.js";
import {formatDate} from "./util.js";

const overlay = document.querySelector(".overlay")
const popupISP = document.querySelector("[data-popup-isp]")
const popupISPEdit = document.querySelector("[data-popup-isp-edit]")
const popupItems = document.querySelectorAll(".popup-isp__item")
const popupISPConfirm = document.querySelector("[data-popup-isp-confirm]")
const popupCancel = document.querySelector(".popup-cancel")
const btnReload = document.querySelector(".popup-isp-edit__reload")
const btnRemoveISP = document.querySelector(".popup-isp-edit__remove")
const btnISPEditConfirm = document.querySelector(".popup-isp-edit__confirm")

let theISPItem

popupItems.forEach(item => {
    item.addEventListener("click", () => {
        item.querySelector(".popup-isp__check").classList.toggle("popup-isp__check--show")
    })
})


popupISPConfirm.addEventListener("click", () => {
     // getting the selected ISPs from popup
    const selectedISPs = [...popupItems].filter(item => {
        return item.querySelector(".popup-isp__check--show")
    }).map(item => {
        return {
            name: item.innerText.trim(),
            date: null,
            apps:[]
        }
    })


    hidePopup(popupISP)
    if (selectedISPs.length) {
        hidePlusIcon(selectedRow.querySelector(".configs__ISP-add-icon"))
        updateISP(selectedRow.dataset.id, selectedISPs)
            .then(renderUpdateTable)
            .catch(error => {
                alert(error.message)
            })
            .finally(() => {
                showPlusIcon(selectedRow.querySelector(".configs__ISP-add-icon"))
            })
    }

})

btnReload.addEventListener("click", () => {
    const reloadIcon = btnReload.querySelector(".popup-isp-edit__reload-icon")
    rotateReloadIcon(reloadIcon)
    const ISPs = [{
        name: theISPItem.innerText.trim(),
        date: theISPItem.dataset.timestamp
    }]
    updateISP(selectedRow.dataset.id, ISPs)
        .then(updatedISPs => {
            theISPItem = renderUpdateTable(updatedISPs)[0]
            renderISPEditPopup()
        })
        .catch(error => {
            alert(error.message)
        })
        .finally(() => {
            stopRotateReloadIcon(reloadIcon)
        })
})

btnRemoveISP.addEventListener("click", () => {
    const removeIcon = btnRemoveISP.querySelector(".popup-isp-edit__remove-icon")
    hideRemoveIcon(removeIcon)
    // tag the ISP to be removed
    theISPItem.dataset.ispDelete = "true"
    const ISPs = getCurrentISPs()
    deleteISP(selectedRow.dataset.id, ISPs)
        .then(()=>{
            theISPItem.remove()
        })
        .catch(error => {
            alert(error.message)
            // untag the ISP, because remove wasn't successful
            theISPItem.dataset.ispDelete = "false"
        })
        .finally(() => {
            showRemoveIcon(removeIcon)
            hidePopup(popupISPEdit)
        })
})

btnISPEditConfirm.addEventListener("click",()=>{
    hidePopup(popupISPEdit)
})

// popupCancel.addEventListener("click", () => {
//     hidePopup(popupISP)
// })

overlay.addEventListener("click", () => {
    hidePopup(popupISP)
    hidePopup(popupISPEdit)
})

function uncheckPopupItems() {
    popupItems.forEach(item => {
        item.querySelector(".popup-isp__check").classList.remove("popup-isp__check--show")
    })
}

function renderISPEditPopup() {
    const popupTitle = document.querySelector(".popup-isp-edit__title")
    popupTitle.innerText = theISPItem.innerText.trim()

    const popupDate = document.querySelector(".popup-isp-edit__date")
    popupDate.innerText = formatDate(theISPItem.dataset.timestamp)
}

export function showPopup(popup, ispItem = false) {
    popup.classList.add("popup--show")
    overlay.classList.add("overlay--show")
    // document.body.classList.add("disable-scroll")
    if (ispItem) {
        theISPItem = ispItem
        renderISPEditPopup()
    }
}

export function hidePopup(popup) {
    popup.classList.remove("popup--show")
    overlay.classList.remove("overlay--show")
    uncheckPopupItems()
    // document.body.classList.remove("disable-scroll")
}

function rotateReloadIcon(i) {
    i.classList.add("fa-spin")
}

function stopRotateReloadIcon(i) {
    i.classList.remove("fa-spin")
}

function hideRemoveIcon(i) {
    i.classList.remove("fa-trash")
    i.classList.add("fa-spinner", "fa-spin-pulse")
}

function showRemoveIcon(i) {
    i.classList.remove("fa-spinner", "fa-spin-pulse")
    i.classList.add("fa-trash")
}
const BASE_URL = "https://bytepack-v2ray-rest.cyclic.app"

export async function getAllConfigs() {
    const url = BASE_URL + "/configs"
    try {
        const res = await fetch(url)
        const result = await res.json()
        if (!res.ok) {
            console.log(`Response wasn't ok. status:${res.status}. statusText:${res.statusText}`)
            throw new Error("1")
        }

        return result

    } catch (error) {
        if (error.message === "1") {
            throw new Error("خطا در انجام عملیات")
        } else {
            console.log("Yo, something is wrong:", error.message)
            throw new Error("ارتباط برقرار نشد")
        }

    }
}

    export async function updateISP(id, ISPs) {
        const url = BASE_URL + "/configs"
        const data = {
            _id: id,
            configISPs: ISPs
        }
        const options = {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(data)
        }
        try {
            const res = await fetch(url, options)
            const result = await res.json()
            if (!res.ok) {
                console.log(`Response wasn't ok. status:${res.status}. statusText:${res.statusText}`)
                console.log(result)
                throw new Error("1")
            }
            return result
        } catch (error) {
            if (error.message === "1") {
                throw new Error("خطا در انجام عملیات")
            } else {
                console.log("Yo, something is wrong:", error.message)
                throw new Error("ارتباط برقرار نشد")
            }
        }

    }

    export async function deleteISP(id, ISPs) {
        const url = BASE_URL + "/configs/delete-isp"
        const data = {
            _id: id,
            configISPs: ISPs
        }
        const options = {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(data)
        }

        try {
            const res = await fetch(url, options)
            const result = await res.json()
            if (!res.ok) {
                console.log(`Response wasn't ok. status:${res.status}. statusText:${res.statusText}`)
                console.log(result)
                throw new Error("1")
            }

        } catch (error) {
            if (error.message === "1") {
                throw new Error("خطا در انجام عملیات")
            } else {
                console.log("Yo, something is wrong:", error.message)
                throw new Error("ارتباط برقرار نشد")
            }
        }
    }
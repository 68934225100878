import {getAllConfigs} from './api.js'
import {showPopup} from "./popup.js";

const templateRow = document.querySelector("[data-template-row]")
const templateISPItem = document.querySelector("[data-template-ISP]")
const tbody = document.querySelector("tbody")
const popupISP = document.querySelector("[data-popup-isp]")
const popupISPEdit = document.querySelector("[data-popup-isp-edit]")
const loadingTable = document.querySelector(".loading-table")
const reloadTable = document.querySelector(".reload-table")


export let selectedRow
export function setupTable(){
    getAllConfigs()
        .then(docs=>{
            hideLoadingTable()
            renderCreateTable(docs)
        })
        .catch(error=>{
            showReloadTable()
        })
}

reloadTable.addEventListener("click", ()=>{
    hideReloadTable()
    setupTable()
})

function renderCreateTable(data) {
    data.forEach((doc, index) => {
        const row = templateRow.content.cloneNode(true)

        const tr = row.querySelector("tr")
        tr.dataset.id = doc._id

        const configNumber = row.querySelector(".configs__number")
        configNumber.innerText = index + 1

        const configName = row.querySelector(".configs__name")
        configName.innerText = doc.configName

        const configType = row.querySelector(".configs__type")
        configType.innerText = doc.configType

        const configList = row.querySelector(".configs__ISP-container")
        renderISPItems(doc.configISPs, configList)

        const fifth = row.querySelector("td:nth-child(5)")
        fifth.dataset.config = doc.configLink


        tbody.appendChild(row)
    })

    setupEventListeners()

}

export function renderUpdateTable(ISPs) {
    const ISPsNames = ISPs.map(isp => isp.name)
    selectedRow.querySelectorAll('[data-isp-delete="false"]').forEach(item => {
        if (ISPsNames.includes(item.innerText.trim())) {
            item.remove()
        }
    })

    const configList = selectedRow.querySelector(".configs__ISP-container")
    const itemsAdded = renderISPItems(ISPs, configList, true)

    setupEventListeners()
    return itemsAdded
}

function renderISPItems(ISPs, configList, update = false) {

    const itemsAdded = []
    ISPs.forEach(isp => {
        const itemTemplate = templateISPItem.content.cloneNode(true)
        const ispItem = itemTemplate.querySelector(".configs__ISP-item")
        ispItem.innerText = isp.name
        ispItem.dataset.ispDelete = "false"
        ispItem.dataset.timestamp = isp.date
        if (update){
            ispItem.classList.add("configs__ISP-item--animation")
        }

        // const ispDate = itemTemplate.querySelector(".configs__ISP-date")
        // ispDate.innerText = formatDate(isp.date)
        // ispDate.dataset.timestamp = isp.date

        const btnAddISP = configList.querySelector(".configs__ISP-add")
        const item = configList.insertBefore(ispItem, btnAddISP)
        itemsAdded.push(item)
    })
    return itemsAdded

}

function setupEventListeners() {
    //isp item
    const ispItems = tbody.querySelectorAll(".configs__ISP-item")
    ispItems.forEach(item => {
        item.addEventListener("click", () => {
            selectedRow = item.closest("[data-id]")
            showPopup(popupISPEdit,item)
        })
    })

    // add icon
    const addISPs = tbody.querySelectorAll(".configs__ISP-add")
    addISPs.forEach(add => {
        add.addEventListener("click", () => {
            showPopup(popupISP)
            selectedRow = add.closest("[data-id]")
        })
    })

    // copy icon
    const btnCopy = tbody.querySelectorAll(".configs__copy-btn")
    btnCopy.forEach(cp => {
        const cpIcon = cp.querySelector(".configs__copy-icon")
        cp.addEventListener("click", () => {
            hideCopyIcon(cpIcon)
            setTimeout(() => showCopyIcon(cpIcon), 2000)
            navigator.clipboard.writeText(cp.parentElement.dataset.config)
        })
    })


}

export function getCurrentISPs() {
    return [...selectedRow.querySelectorAll('[data-isp-delete="false"]')]
        .map(item => {
            return {
                name: item.innerText.trim(),
                date: parseFloat(item.dataset.timestamp),
                apps:[]
            }
        })
}

function hideCopyIcon(i) {
    i.classList.remove("fa-regular", "fa-copy")
    i.classList.add("fa-solid", "fa-check")
}

function showCopyIcon(i) {
    i.classList.remove("fa-solid", "fa-check")
    i.classList.add("fa-regular", "fa-copy")
}




export function hidePlusIcon(i) {
    i.classList.remove("fa-circle-plus")
    i.classList.add("fa-spinner", "fa-spin-pulse")
}

export function showPlusIcon(i) {
    i.classList.remove("fa-spinner", "fa-spin-pulse")
    i.classList.add("fa-circle-plus")
}

function hideLoadingTable(){
    loadingTable.classList.add("loading-table--hide")
}

function showReloadTable(){
    loadingTable.classList.add("loading-table--hide")
    reloadTable.classList.add("reload-table--show")
}

function hideReloadTable(){
    reloadTable.classList.remove("reload-table--show")
    loadingTable.classList.remove("loading-table--hide")
}


// TODO handle table show if fetch wasn't successful
